<template>
    <div class="replyList-wrapper">
        <div class="content">
            <el-breadcrumb separator=">">
                <el-breadcrumb-item>公众号/小程序</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/officialAccountsOrApplet' }">授权公众号</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/publicAccountManagement', query: { appid: appid, nickName: nickName, headImg: headImg } }">公众号管理</el-breadcrumb-item>
                <el-breadcrumb-item>自动回复</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="title-item">
                <div class="title-left">
                    <div class="title-t">关键词回复</div>
                    <!-- <div class="elSwitch">
                        <el-switch v-model="keyValue" active-color="#FF6056" />
                    </div> -->
                </div>

                <div class="title-right">
                    <div class="rule-item">
                        <el-input type="text" v-model="ruleKey" placeholder="搜索关键词/规则名称" clearable @change="keywordReplySetting">
                            <template #append>
                                <el-button slot="append" icon="el-icon-search" @click="keywordReplySetting"></el-button>
                            </template>
                        </el-input>
                    </div>

                    <div class="btns newReply-btn" @click="linkNewReply">新增回复</div>
                    <div class="btns" v-if="false">一键同步</div>
                </div>
            </div>

            <div class="table">
                <el-table :data="settingData" style="width: 100%;" height="100%" >
                    <el-table-column fixed prop="id" label="ID" width="160" align="center"></el-table-column>
                    
                    <el-table-column prop="key" label="关键词" width="290" align="center"></el-table-column>

                    <el-table-column prop="" label="回复类型" width="160" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.reply_type == 1">文本</div>
                            <div v-if="scope.row.reply_type == 2">图片</div>
                            <div v-if="scope.row.reply_type == 3">小程序卡片</div>
                            <div v-if="scope.row.reply_type == 4">图文</div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="text" label="回复内容" width="430" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.reply_type == 1" v-html="scope.row.text"></div>
                            <div class="image_url" v-if="scope.row.reply_type == 2">
                                <img :src="scope.row.image_url" />
                            </div>
                            <div class="pages-item" v-if="scope.row.reply_type == 3">
                                <div>
                                    <div class="pages-name">
                                        <div class="pages-img">
                                            <img src="../../assets/xs-icon.png" />
                                        </div>
                                        <div class="pages-t">小程序名称</div>
                                    </div>
                                    <div class="pages-title">{{scope.row.title}}</div>
                                    <div class="pages-url">
                                        <img :src="scope.row.url" />
                                    </div>
                                    <div class="pages-name">
                                        <div class="pages-img">
                                            <img src="../../assets/xs-icon.png" />
                                        </div>
                                        <div class="pages-t">小程序</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="scope.row.reply_type == 4">图文</div> -->
                        </template>
                    </el-table-column>

                    <!-- <el-table-column prop="" label="匹配" width="160" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.match_type == 2">匹配</div>
                            <div v-if="scope.row.match_type == 1">半匹配</div>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="创建时间" width="240" align="center">
                        <template slot-scope="scope">
                            <div>{{scope.row.create_time | dateFormat}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" width="180" align="center">
                        <template slot-scope="scope">
                            
                            <el-switch
                             v-model="scope.row.config_status"
                             active-color="#FF6056" @change="changeSwitch(scope.row.id, scope.row.config_status)">
                            </el-switch>
                        </template>
                    </el-table-column>


                    <el-table-column fixed="right" label="操作" width="180" align="center">
                        <template slot-scope="scope">
                            <div class="btn-wrap">
                                <div class="btn" @click="edit(scope.row)">编辑</div>
                                <div class="btn del-btn" @click="delItem(scope.$index, scope.row.id)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="paging-item" v-if="settingData.length > 0">
                <el-pagination background @size-change="handleSizeChange"
                 @current-change="handleCurrentChange" :current-page.sync="currentPage"
                 layout="total, prev, pager, next, jumper" :total="signalTotal">
                </el-pagination>
            </div>
            


        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import global from '@/assets/js/global';
    export default {
        components: {},
        data() {
            return {
                keyValue: '',
                ruleKey: '',
                currentPage: 1,
                pageSize: 10,
                signalTotal: 0,
                settingData: [], // 订单详情数据
                loading: false, // 加载状态
                page: 1,
                maxPage: 10,
                stateValue: true,
                appid: this.$route.query.appid,
                nickName: this.$route.query.nickName,
                headImg: this.$route.query.headImg,

            }
        },
        created() {
            this.keywordReplySetting();
        },
        methods: {
            // 新增回复
            linkNewReply() {
                this.$router.push({
                    path: '/newReply',
                    query: {
                        appid: this.appid,
                        nickName: this.nickName,
                        headImg: this.headImg
                    }
                });
            },

            // 
            keywordReplySetting() {
                axios.get(global.wechatURL + "third/keyword_reply_setting", {
                    params: {
                        appid: this.appid,
                        key: this.ruleKey,
                        page: this.page,
                        max_page: this.maxPage
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var List = res.data.data.data;
                        List.forEach((item) => {
                            if(item.config_status == 0) {
                                item.config_status = false;
                            } else if(item.config_status == 1) {
                                item.config_status = true;
                            }
                        })
                        this.settingData = List;
                        // console.log(this.settingData)
                        this.signalTotal = res.data.data.count;
                    } else {
                        this.$message({ message: res.data.message, type: 'error' })
                    }
                });
            },
            // 
            changeSwitch(id, status) {
                var textType
                if(status == true) {
                    textType = 1;
                } else if(status == false) {
                    textType = 0;
                }
                axios.put(global.wechatURL + "third/keyword_reply_status", {
                    appid: this.appid,
                    id: id,
                    status: textType,
                }).then(res => {
                    if(res.data.status == 200) {
                        this.$message({ message: "修改成功!", type: 'success' })
                    } else {
                        this.$message({ message: res.data.message, type: 'error' })
                    }
                });
            },
            // 删除标签
            delItem(index, id) {
                var that = this;
                that.$confirm('确定要删除规则吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axios.delete(global.wechatURL + "third/keyword_reply_setting", {
                        params: {
                            appid: that.appid,
                            id: id
                        }
                    }).then(res => {
                        if(res.data.status == 200) {
                            that.$message({
                                message: '删除成功!',
                                type: 'warning'
                            })
                            that.settingData.splice(index, 1);
                        } else {
                            this.$message({ showClose: true, message: res.data.message, type: 'error'})
                        }
                    });
                }).catch(() => {
                    console.log('取消删除');
                });
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                
            },
            handleCurrentChange(val) {
                this.page = val;
                this.keywordReplySetting();
            },
            edit(item) {
                this.$router.push({
                    path: '/newReply',
                    query: {
                        appid: this.appid,
                        item: item
                    }
                });
            }

        }
    }
</script>

<style lang="less" scoped>
    .table /deep/ a, a:active, a:hover, a:visited {
        text-decoration: none;
    }
    .content {
        padding: 0 25px;
    }

    // .replyList-wrapper /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    //     color: #1A54EC;
    // }

    .title-item {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-left {
            display: flex;
            align-items: center;
            .title-t {
                margin-right: 35px;
                font-size: 18px;
                font-weight: bold;
                color: #252530;
            }
        }
        .title-right {
            display: flex;
            align-items: center;
            .rule-item {
                margin-right: 20px;
                /deep/.el-input-group__append, .el-input-group__prepend {
                    background-color: #FF6056;
                    color: #ffffff;
                    border:none;
                }
            }
            .btns {
                width: 100px;
                height: 38px;
                background: #FFFFFF;
                border: 1px solid #3E3F54;
                border-radius: 6px;
                font-size: 14px;
                color: #3E3F54;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
            }
            .newReply-btn {
                margin-right: 20px;
            }
        }
    }
    
    .table {
        padding-top: 20px;
        width: 100%;
        height: calc(100vh - 265px);
    }
    .table /deep/ .el-table th.el-table__cell {
        background: #F6F7FB;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .image_url {
        margin: 0 auto;
        width: 100px;
        height: 100px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .pages-item {
        display: flex;
        justify-content: center;
        .pages-name {
            display: flex;
            align-items: center;
            .pages-img {
                margin-right: 5px;
                width: 13px;
                height: 13px;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .pages-t {
                font-size: 12px;
                color: #a3a4b3;
            }
        }
        .pages-title {
            font-size: 12px;
            color: #333;
        }
        .pages-url {
            width: 100px;
            height: 100px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    .btn-wrap {
        display: flex;
        align-items: center;
        .btn {
            flex: 1;
            font-size: 14px;
            color: #1A54EC;
            cursor: pointer;
        }
        .del-btn {
            color: #FF6056;
        }
    }
    
    .paging-item {
        display: flex;
        justify-content: center;
    }

</style>
